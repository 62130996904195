// ? TabsItems
import {
    mapGetters,
    mapActions,
    mapState
} from 'vuex'
// ? SERVICE
import service        from '@/services/soporte'
import DialogForm     from '@/components/Dialogs/DialogForm/DialogForm.vue'

export default {
    name : "CreateTicket",
    components : {
        DialogForm
    },
    props : {
        dialogCierre : {
            type    : Boolean,
            default : false
        },
        oldObject : {
            type    : Object,
            default : () => {}
        }
    },
    data : () => ({
        title       : "Nuevo",
        valid       : true,
        lazy        : false,
        idTrTicket  : -1,
        folio       : undefined,
        titulo      : undefined,
        respuesta   : {},
        calificacion: undefined,
        rating      : undefined,
        updated_at  : undefined,
        questions   : undefined,
        observacionesCierre : undefined,
        items       : {
            evento   : [],
            usuario  : [],
            sucursal : [],
            servicio : []
        },
        errores     : {
            folio       : false,
            titulo      : false,
            respuesta   : false,
        },
        rules       : {
            folio        : [],
            titulo       : [v => !!v || 'El titulo es requerido'],
            respuesta    : [v => !!v || 'La respuesta es requerida'],
            calificacion : [v => !!v || 'La calificación es requerida'],
        }
    }),
    watch : {
        'oldObject' : function() {
            this.idTrTicket     = this.oldObject.id_soporteTrTicket;
            this.folio          = this.oldObject.folio;
            this.titulo         = this.oldObject.titulo;
            this.updated_at     = this.oldObject.updated_at; // asignación de fecha de actualización
            this.questions.forEach( element => {
                this.respuesta[element.id] = 0;
            });
        },
    },
    computed : {
        ...mapGetters("Ticket", {
            dataSource : "getDataSource"
        }),
    },
    methods : {
        ...mapActions("DialogForm",["setDialog"]),
        ...mapActions("Ticket",  ["setDataSource"] ),
        eventClose () {
            this.reset();
            // this.$emit("closeDialogCierre", false)
            this.setDialog({
                key    : "dialogCierre",
                status : false
            });
        },
        eventGen () {
            this.CierreTicket();
        },
        CierreTicket(){
            if( this.validate () ) {
                let {objTicket, arrRlEncuetaTicket } = this.buildObject();
                let arrInfo  = [];
                arrInfo.push(this.$JsTable.getFormat("soporteTrTicket",         "Update", [objTicket],  ["id_soporteTrTicket"]));
                arrInfo.push(this.$JsTable.getFormat("soporteRlEncuestaTicket", "Insert", arrRlEncuetaTicket,  ["id_soporteRlEncuestaTicket"]));
                service.SopU_closeTicket( arrInfo ).then( result => {
                    if( this.$Msg.fn( {data : result, message : "Ticket cerrado correctamente" } )) {
                        this.eventClose();
                        this.readTableTicket();
                    }
                })
            }
        },
        getTitle() {
            return "Cierre de ticket";
        },
        buildObject(){
            let objTicket           = {},
                arrRlEncuetaTicket  = [],
                cont                = -1;
            objTicket = {
                id_soporteTrTicket     : this.idTrTicket,
                id_baseCtEstatus       : 5,
                updated_at             : this.updated_at
            }
            for ( const resp in this.respuesta ) {
                arrRlEncuetaTicket.push({
                    id_soporteRlEncuestaTicket : cont--,
                    id_soporteTrTicket         : this.idTrTicket,
                    id_soporteCtEncuesta       : parseInt(resp),
                    observacion                : this.observacionesCierre,
                    respuesta                  : this.respuesta[resp],
                    activo                     : 1
                })
            }

            return { objTicket, arrRlEncuetaTicket };
        },
        // ? Validaciones del form
        validate () {
            try {
                for ( const resp in this.respuesta ) {
                    if ( this.respuesta[ resp ] == 0 )
                        throw "Debe seleccionar una calificación"
                }
            } catch (error) {
                this.$Msg.error( error )
                return false;
            }
            return true;            
        },
        reset () {
            this.$refs.form.reset();
        },
        resetValidation () {
            this.$refs.form.resetValidation()
        },
        readTableTicket() {
            service.SopR_dataSourceTickets().then( result => {
                this.setDataSource( this.$JsTable.toObj( result.data ))
            })
        },
    },
    created() {
        service.SopR_dataCierreTicket().then( result => {
            this.questions = this.$JsTable.toObj( result.data )
        })
    },
    mounted() {
    }
}
